import React, { useState, useRef, useEffect } from 'react';

const vow = {
  m1:"אני, יורי פיילר, לוקח אותך לאשתי",
  m2:"היי אשתי לשארית חיי",
  m3:"היי חברתי הטובה",
  m4:"היי אם ילדיי",
  m5:"יחדיו נעמוד בכל הקשיים והאתגרים ואני לעולם לא אותיר אותך לבדיך",
  m6:"במילים הללו אני לוקח אותך לאשתי ומחבר את חיי בחייך",
  f1:"אני, קרן אבי אבן, לוקחת אותך לאישי",
  f2:"הייה אישי לשארית חיי",
  f3:"הייה חברי הטוב",
  f4:"הייה אב ילדיי",
  f5:"יחדיו נעמוד בכל הקשיים והאתגרים ואני לעולם לא אותיר אותך לבדך",
  f6:"במילים הללו אני לוקחת אותך לאישי ומחברת חיי בחייך"
}

export default () => {

  const [ play, setPlay ] = useState(false);

  const player = useRef(null);

  useEffect(() => {
    if (play) {
      player.current.volume = 0.1
      player.current.play();
    }
  } , [ play ]);

    return (
      <>
        <header className="logo">
          {/*Yuri & Keren*/}
          <img className="logoyk" src="/images/logoyk.svg" alt="logo" />
          <div className="date">18-10-18</div>
        </header>
        {play && ( <>
        <section className="hero">
          <div className="thanks">Thank you! I am happy with you</div>
        </section>
        <div className="fiance">
        <div className="phrase1">{vow.m1}</div>
        <div className="phrase2">{vow.m2}</div>
        <div className="phrase3">{vow.m3}</div>
        <div className="phrase4">{vow.m4}</div>
        <div className="phrase5">{vow.m5}</div>
        <div className="phrase6">{vow.m6}</div>
        </div>
        <div className="fiancee">
        <span className="phrase1">{vow.f1}</span><br/>
        <span className="phrase2">{vow.f2}</span><br/>
        <span className="phrase3">{vow.f3}</span><br/>
        <span className="phrase4">{vow.f4}</span><br/>
        <span className="phrase5">{vow.f5}</span><br/>
        <span className="phrase6">{vow.f6}</span><br/>
        </div>
            </>)}
        { ! play && (<div role="button" className="volume" onClick={() => {setPlay(true)}}>
          <img src="/images/volume.svg" alt="logo" />
        </div>)}
        <audio ref={player} id="song" autoPlay>
          <source src="/music/UnchainedMelody.mp3" type="audio/mp3" />
        </audio>
      </>
    )
}



